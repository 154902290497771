<template>
  <div class="row justify-content-md-center">
    <div class="col-xl-4 col-lg-5 col-md-6 col-sm-12">
      <div class="login-screen">
        <div class="login-box">
          <a href="#" class="login-logo">Login</a>
          <h5>Welcome back,<br />Please Login to your Account.</h5>

          <div class="form-group">
            <input
              type="email"
              class="form-control"
              :class="{'is-invalid': state.fields.email.error}"
              name="email"
              v-model="state.fields.email.value"
              autocomplete="email"
              placeholder="Email Address"
              autofocus
            />
          </div>

          <div class="form-group">
            <input
              type="password"
              class="form-control"
              :class="{'is-invalid': state.fields.email.error}"
              name="password"
              autocomplete="current-password"
              placeholder="Password"
              v-model="state.fields.password"
              @keypress.enter="handleLogin"
            />
          </div>

          <div
            v-if="state.fields.email.error"
            class="text-danger text-center mb-3"
          >
            {{ state.fields.email.errorMsg }}
          </div>

          <div class="actions mb-4 justify-content-between">
            <div class="custom-control custom-checkbox">
              <input
                type="checkbox"
                class="custom-control-input"
                id="remember_pwd"
                name="remember"
                v-model="state.fields.remember"
              >
              <label class="custom-control-label" for="remember_pwd">Remember me</label>
            </div>
            <button
              type="submit"
              class="btn btn-success d-flex"
              @click.prevent="handleLogin"
            >
              <spinner
                classes="me-1"
                v-if="state.loading"
                type="li"
                size="20"
              />
              <div>Login</div>
            </button>
          </div>

          <div class="forgot-pwd">
            <a
              class="link"
              :href="state.forgetPasswordRoute"
              target="_blank"
            >
              Forgot password?
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import routeMap from '@/utils/routeMap';
import { useStore } from 'vuex';
import { reactive, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { RESET_PASSWORD_URL } from '@/utils/constants';
import Spinner from '@/components/Util/Spinner';

export default {
  name: 'Login',
  components: { Spinner },
  setup () {

    const store = useStore();

    const userState = store.state.user;

    const state = reactive({
      loading: false,
      forgetPasswordRoute: RESET_PASSWORD_URL,
      fields: {
        email: {
          value: '',
          error: false,
          errorMsg: ''
        },

        password: '',
        remember: false,
      }
    });

    watch(userState, (nv) => {
      state.loading = userState.loading;
      state.fields.email.error = !!nv.errorMsg.length;
      state.fields.email.errorMsg = nv.errors?.email?.[0];
    });

    const router = useRouter();
    const route = useRoute();

    const handleLogin = async () => {

      const data = {
        email: state.fields.email.value,
        password: state.fields.password,
        remember: state.fields.remember,
        device_name: 'web'
      };

      const redirectFrom = route.query.redirect;
      const redirectTo = redirectFrom ? { path: redirectFrom } : { name: routeMap.dashboard.name };

      await store.dispatch('user/tryLogin', data);

      if (userState.authenticated) {
        router.push(redirectTo);
      }

    };

    return {
      state,
      handleLogin
    };

  }
};
</script>

<style scoped>

</style>
